import React, { useState, useCallback } from "react"
import update from "immutability-helper"
import { throttle } from "lodash"
import {
  LegacyCard,
  TextField,
  FormLayout,
  LegacyStack,
  Select,
  Button,
  Modal,
  ChoiceList,
  Thumbnail,
  Badge,
  Checkbox,
  Popover,
  ColorPicker,
} from "@shopify/polaris"
import styled from "styled-components"
function hslToHex(h, s, l) {
  l /= 100
  const a = (s * Math.min(l, 1 - l)) / 100
  const f = (n) => {
    const k = (n + h / 30) % 12
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1)
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, "0") // convert to Hex and prefix "0" if needed
  }
  return `#${f(0)}${f(8)}${f(4)}`
}
const RewardsBarWrapper = styled.div`
  margin-top: 30px;
  .rewards-bar-title {
    display: block;
    padding-bottom: 5px;
    font-weight: 600;
  }
`

function RewardsBar(props) {
  const { state, setState, currencySymbol } = props
  const [
    barBackgroundColorPopoverVisible,
    setBarBackgroundColorPopoverVisible,
  ] = useState(false)
  const [barBackgroundColorHsl, setBarBackgroundColorHsl] = useState({
    hue: 137,
    saturation: 31,
    brightness: 1,
    alpha: 1,
  })
  const [barColorPopoverVisible, setBarColorPopoverVisible] = useState(false)
  const [barColorHsl, setBarColorHsl] = useState({
    hue: 211,
    saturation: 39,
    brightness: 21,
    alpha: 1,
  })

  const barBackgroundColorActivatorButton = (
    <TextField
      label="Bar background color"
      onFocus={() => setBarBackgroundColorPopoverVisible(true)}
      value={state?.rewardsBar?.settings?.[0]?.barBackgroundColor}
      onChange={(val) =>
        setState(
          update(state, {
            rewardsBar: {
              settings: { [0]: { barBackgroundColor: { $set: val } } },
            },
          })
        )
      }
      connectedRight={
        <div
          style={{
            borderRadius: 5,
            cursor: "pointer",
            height: "100%",
            width: 33,
            backgroundColor:
              state?.rewardsBar?.settings?.[0]?.barBackgroundColor,
          }}
          onClick={() => setBarBackgroundColorPopoverVisible(true)}
        />
      }
    />
  )

  const onBarBackgroundColorChange = (updatedColor) => {
    const color = {
      hue: updatedColor.hue || barBackgroundColorHsl.hue || 0,
      saturation:
        updatedColor.saturation || barBackgroundColorHsl.saturation || 0,
      brightness:
        updatedColor.brightness || barBackgroundColorHsl.brightness || 0,
      alpha: 1,
    }
    const hex = hslToHex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100
    )

    setBarBackgroundColorHsl(color)
    setState(
      update(state, {
        rewardsBar: {
          settings: { [0]: { barBackgroundColor: { $set: hex } } },
        },
      })
    )
  }
  const onBarBackgroundColorChangeThrottled = throttle(
    onBarBackgroundColorChange,
    250
  )
  const barColorActivatorButton = (
    <TextField
      label="Bar color"
      onFocus={() => setBarColorPopoverVisible(true)}
      value={state?.rewardsBar?.settings?.[0]?.barColor}
      onChange={(val) =>
        setState(
          update(state, {
            rewardsBar: {
              settings: { [0]: { barColor: { $set: val } } },
            },
          })
        )
      }
      connectedRight={
        <div
          style={{
            borderRadius: 5,
            cursor: "pointer",
            height: "100%",
            width: 33,
            backgroundColor: state?.rewardsBar?.settings?.[0]?.barColor,
          }}
          onClick={() => setBarColorPopoverVisible(true)}
        />
      }
    />
  )
  const onBarColorChange = (updatedColor) => {
    const color = {
      hue: updatedColor.hue || barColorHsl.hue || 0,
      saturation: updatedColor.saturation || barColorHsl.saturation || 0,
      brightness: updatedColor.brightness || barColorHsl.brightness || 0,
      alpha: 1,
    }
    const hex = hslToHex(
      color.hue,
      color.saturation * 100,
      color.brightness * 100
    )

    setBarColorHsl(color)
    setState(
      update(state, {
        rewardsBar: {
          settings: { [0]: { barColor: { $set: hex } } },
        },
      })
    )
  }
  const onBarColorChangeThrottled = throttle(onBarColorChange, 250)
  console.log("barBackgroundColorHsl", barBackgroundColorHsl)
  return (
    <FormLayout>
      <RewardsBarWrapper>
        <span className="rewards-bar-title">Rewards bar</span>
        <LegacyCard>
          <LegacyCard.Section>
            <FormLayout>
              <Checkbox
                label="Enable rewards bar"
                checked={state?.rewardsBar?.enabled}
                onChange={(val) =>
                  setState(
                    update(state, {
                      rewardsBar: {
                        enabled: { $set: val },
                      },
                    })
                  )
                }
              />
              <TextField
                label="Goal amount"
                placeholder="100"
                prefix={currencySymbol}
                type="number"
                onChange={(val) => {
                  setState(
                    update(state, {
                      rewardsBar: {
                        settings: { [0]: { goalAmount: { $set: val } } },
                      },
                    })
                  )
                }}
                value={state?.rewardsBar?.settings?.[0]?.goalAmount}
              />
              <TextField
                label="Text before reaching goal amount"
                placeholder="You’re {AMOUNT} away from free shipping!"
                onChange={(val) => {
                  setState(
                    update(state, {
                      rewardsBar: {
                        settings: { [0]: { textBefore: { $set: val } } },
                      },
                    })
                  )
                }}
                value={state?.rewardsBar?.settings?.[0]?.textBefore}
              />
              <TextField
                label="Text after reaching goal amount"
                placeholder="You’ve unlocked free shipping!"
                onChange={(val) => {
                  setState(
                    update(state, {
                      rewardsBar: {
                        settings: { [0]: { textAfter: { $set: val } } },
                      },
                    })
                  )
                }}
                value={state?.rewardsBar?.settings?.[0]?.textAfter}
              />
              <LegacyStack spacing="tight">
                <Popover
                  active={barColorPopoverVisible}
                  activator={barColorActivatorButton}
                  onClose={() => setBarColorPopoverVisible(false)}
                >
                  <ColorPicker
                    color={barColorHsl}
                    onChange={onBarColorChangeThrottled}
                  />
                </Popover>
                <Popover
                  active={barBackgroundColorPopoverVisible}
                  activator={barBackgroundColorActivatorButton}
                  onClose={() => setBarBackgroundColorPopoverVisible(false)}
                >
                  <ColorPicker
                    color={barBackgroundColorHsl}
                    onChange={onBarBackgroundColorChangeThrottled}
                  />
                </Popover>
              </LegacyStack>
            </FormLayout>
          </LegacyCard.Section>
        </LegacyCard>
      </RewardsBarWrapper>
    </FormLayout>
  );
}

export default RewardsBar
